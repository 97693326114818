.o-richtext {
  * + * {
    margin-top: var(--vr);
  }

  * + h2,
  * + h3 {
    margin-top: calc(2 * var(--vr));
  }

  ul {
    list-style-type: disc;
    margin-left: 2rem;
  }

  li + li {
    margin-top: calc(var(--vr) / 2);
  }

  h2 + h3 {
    margin-top: var(--vr);
  }

  pre {
    display: block;
    padding: 1rem;
    max-width: 40rem;
  }

  code,
  pre {
    background-color: lighten($lightGrey, 10%);
    border-radius: 0.25rem;
  }

  h1 {
    margin-bottom: calc(2 * var(--vr));
    padding-bottom: var(--vr);
    border-bottom: 0.2rem solid;
  }

  h1,
  h2,
  h3 {
    color: lighten($bgDark, 12%);
  }

  a {
    &:hover,
    &:focus {
      background-color: $accent;
      transition: color 200ms, background-color 200ms;
    }
  }
}
