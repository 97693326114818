:root {
  --vr: 1rem;
}

@media (min-width: 45em) {
  :root {
    --vr: 2rem;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

img {
  max-width: 100%;
  display: block;
}

svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

@font-face {
  font-family: LetterGothicStd;
  src: url("LetterGothicStd.3a4da931.otf") format("opentype");
}

@font-face {
  font-family: Milkstore01-Clean;
  src: url("Milkstore01-Clean.f227137c.ttf") format("truetype");
}

body {
  font-family: LetterGothicStd, Lato, sans-serif;
}

@media (min-width: 45em) {
  body {
    font-size: 1.2rem;
  }
}

h1, h2, h3, h4 {
  margin: 0;
  font-family: LetterGothicStd, Amiri, serif;
  font-weight: 700;
  line-height: 1.1;
}

h2, h3 {
  letter-spacing: -.02em;
}

h1 {
  letter-spacing: -.03em;
  line-height: 1.1;
}

@media (min-width: 45em) {
  h1 {
    font-size: 4.5rem;
  }
}

h2 {
  line-height: 1.2;
}

@media (min-width: 45em) {
  h2 {
    font-size: 2.8rem;
  }
}

h3 {
  text-transform: lowercase;
  font-family: Milkstore01-Clean, Lato, sans-serif;
  font-size: 1.1rem;
}

@media (min-width: 45em) {
  h3 {
    font-size: 1.5rem;
  }
}

p {
  line-height: 1.5;
}

a {
  color: #a27669;
}

a:hover, a:focus {
  color: #66483f;
}

body {
  color: #66483f;
  min-height: 100vh;
  width: 100vw;
  font-display: swap;
  background-color: #ece8c5;
  flex-direction: column;
  display: flex;
}

.container {
  max-width: 60rem;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 45em) {
  .container {
    padding: 0 2rem;
  }
}

.page-content {
  flex-grow: 1;
  padding: 7rem 0 2rem;
}

@media (min-width: 45em) {
  .page-content {
    padding: 10rem 0;
  }
}

.o-richtext * + * {
  margin-top: var(--vr);
}

.o-richtext * + h2, .o-richtext * + h3 {
  margin-top: calc(2 * var(--vr));
}

.o-richtext ul {
  margin-left: 2rem;
  list-style-type: disc;
}

.o-richtext li + li {
  margin-top: calc(var(--vr) / 2);
}

.o-richtext h2 + h3 {
  margin-top: var(--vr);
}

.o-richtext pre {
  max-width: 40rem;
  padding: 1rem;
  display: block;
}

.o-richtext code, .o-richtext pre {
  background-color: #e8e8e9;
  border-radius: .25rem;
}

.o-richtext h1 {
  margin-bottom: calc(2 * var(--vr));
  padding-bottom: var(--vr);
  border-bottom: .2rem solid;
}

.o-richtext h1, .o-richtext h2, .o-richtext h3 {
  color: #8c6356;
}

.o-richtext a:hover, .o-richtext a:focus {
  background-color: #769180;
  transition: color .2s, background-color .2s;
}

.c-header {
  width: 100%;
  padding: 1rem 1rem .5rem;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 45em) {
  .c-header {
    min-height: 4.5rem;
    align-items: center;
    padding: .5rem 1rem .5rem 2rem;
    display: flex;
  }
}

.c-header--bg {
  background-color: #66483f;
}

.c-header__menu-btn {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: #cdced1;
  z-index: 2;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin-left: auto;
  padding: 1rem;
  font-family: inherit;
  font-size: 1rem;
  text-decoration: underline;
  transition: color .2s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-header__menu-btn:hover, .c-header__menu-btn:focus {
  color: #ece8c5;
}

@media (min-width: 45em) {
  .c-header__menu-btn {
    font-size: 1.2rem;
    top: .5rem;
    right: 1.5rem;
  }
}

.c-header__nav {
  margin-left: auto;
}

.c-header__list {
  flex-wrap: wrap;
  margin-left: -1rem;
  display: flex;
}

@media (min-width: 70em) {
  .c-header__list {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

.c-header__link {
  color: #cdced1;
  height: 100%;
  align-items: center;
  padding: .5rem 1rem;
  text-decoration: none;
  display: flex;
}

.c-header__link svg {
  width: 1.75rem;
  height: 1.75rem;
  margin-left: .5rem;
}

.c-header__link:hover, .c-header__link:focus {
  color: #ece8c5;
  text-decoration: underline;
}

@media (min-width: 45em) {
  .c-header__link {
    font-size: 1rem;
  }
}

@media (min-width: 70em) {
  .c-header__link {
    font-size: 1.1rem;
  }
}

.c-header__title {
  text-transform: none;
  max-width: calc(100% - 55px);
  margin-bottom: .5rem;
  font-family: LetterGothicStd, sans-serif;
  font-size: 1.4rem;
  line-height: 1.1;
}

.c-header__title a {
  color: #ece8c5;
  text-decoration: none;
  display: block;
}

.c-header__title a:hover, .c-header__title a:focus {
  color: #769180;
}

@media (min-width: 45em) {
  .c-header__title {
    margin-bottom: 0;
    padding: .5rem 0;
    font-size: 2rem;
  }
}

.js-menu .c-header__nav {
  width: 100%;
  max-width: 40rem;
  height: 100vh;
  background-color: #563d35;
  padding: 5rem 1rem 2rem;
  transition: transform .25s;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: scroll;
  transform: translate3d(100%, 0, 0);
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, .25);
}

@media (min-width: 45em) {
  .js-menu .c-header__nav {
    padding: 5rem 2rem 2rem;
  }

  .js-menu .c-header__nav .c-header__link {
    font-size: 1.2rem;
  }
}

.js-menu .c-header__list {
  margin-left: 0;
  display: block;
}

.is-visible .c-header__nav {
  transform: translate3d(0, 0, 0);
}

.c-footer {
  color: #ece8c5;
  background-color: #563d35;
  padding: 1rem;
}

@media (min-width: 45em) {
  .c-footer {
    padding: 1rem 2rem;
  }
}

.c-footer a {
  color: #ece8c5;
}

.c-footer a:hover {
  text-decoration: none;
}

.c-hero {
  min-height: 100vh;
  text-align: center;
  background-position: center;
  background-repeat: repeat;
  background-size: 6.6rem 6.6rem, 100% 100%;
  align-items: center;
  padding: 6rem 0 5rem;
  display: flex;
}

@media (min-width: 45em) {
  .c-hero {
    padding: 5rem 0;
  }
}

@media (min-width: 70em) {
  .c-hero__heading {
    font-size: 6rem;
  }
}

.c-hero__desc {
  max-width: 50ch;
  margin: 0 auto;
}

@media (min-width: 45em) {
  .c-hero__desc {
    font-size: 1.4rem;
  }
}

.c-hero__link-wrapper {
  max-width: 20rem;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.c-hero__link-wrapper h3 {
  width: 100%;
  font-weight: normal;
}

@media (min-width: 45em) {
  .c-hero__link-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.c-hero__link {
  color: #769180;
  margin-top: var(--vr);
}

.c-hero__link:hover, .c-hero__link:focus {
  color: #cdced1;
}

.c-hero__link--github {
  color: #cdced1;
  align-items: center;
  font-size: 80%;
  display: inline-flex;
}

.c-hero__link--github:hover, .c-hero__link--github:focus {
  color: #ece8c5;
}

.c-hero__link--github svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: .5rem;
}

form input {
  min-height: 35px;
  min-width: 250px;
  font-size: 18px;
}

form textarea {
  font-size: 18px;
}

form button {
  border-radius: none;
  box-shadow: none;
  color: #ece8c5;
  background-color: #66483f;
  border: 0;
  padding: 10px 15px;
  font-size: 20px;
}

.hidden {
  display: none;
}

/*# sourceMappingURL=styles.css.map */
