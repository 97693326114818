* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  display: block;
}

svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
