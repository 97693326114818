/* Breakpoints */

$md: 45em;
$lg: 70em;

/* Colors */
$white: #ECE8C5;
$lightGrey: #cdced1;

$bgDark: #66483F;
$accent: #769180;
$alt: darken($bgDark, 5%);

/* CSS vars */

:root {
  --vr: 1rem;

  @media (min-width: $md) {
    --vr: 2rem;
  }
}
