.c-footer {
  padding: 1rem;
  color: $white;
  background-color: darken($bgDark, 5%);

  @media (min-width: $md) {
    padding: 1rem 2rem;
  }

  a {
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }
}
