form {
    input {
        min-height: 35px;
        min-width: 250px;
        font-size: 18px;
    }
    textarea {
        font-size: 18px;
    }

    button {
        background-color: $bgDark;
        padding: 10px 15px;
        border-radius: none;
        box-shadow: none;
        color: $white;
        border: 0;
        font-size: 20px;
    }
}